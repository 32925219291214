.login-header{


}

.customer-card{
    overflow: hidden;
    /* padding: 0; */
    align-self: unset !important;
}

.toggle-customer-balances{
    display: flex;
    flex-direction: row;
    transition: all 0.2s ease-out;
    background-color: var(--prim);
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.toggle-span{
    transition: all 0.2s ease-out;
    text-align: center;
    cursor: pointer;
    padding: var(--md);
    color: var(--goat);
}


.toggle-customer-balances .active-span{
    box-shadow: unset;
    color: var(--crow);
    background-color: var(--throne);
    border-radius: var(--md);
}

[is="Debt"]{border-bottom: solid 1px var(--green);}
[is="Credit"]{border-bottom: solid 1px var(--orange);}

/* I T E M S */
.add-to-cart-container{
    background-color: var(--throne);
    padding: var(--sm) var(--md);
    border-radius: var(--md);
    box-shadow: var(--shadow-lg);
}
/* I T E M S */
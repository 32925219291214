.transition-0{ transition: all 0s ease-in-out;}
.transition-1{ transition: all 0.1s ease-in-out;}
.transition-2{ transition: all 0.2s ease-in-out;}
.transition-3{ transition: all 0.3s ease-in-out;}
.transition-4{ transition: all 0.4s ease-in-out;}

.shake {
  animation: shakeFrame 500ms linear !important;
  box-shadow: 0 0 8px #f8717155 !important;
  content: "Must Be Filled" !important;
}
.shake::placeholder {
  color: var(--dragon) !important;
}
@keyframes shakeFrame {
  0%,20%,40%,60%,80% {transform: translateX(0);}
  10%,30%,50%,70%,90% {transform: translateX(-6px);}
}

.blur-bg{
    background-color: #0004;
}

@keyframes searching {
  0% , 100% {
    transform: translate(0px, 0px)
  }
  10% , 90% {
    transform: translate(15px, -10px)
  }
  20% , 80% {
     transform: translate(25px, -35px)
  }
  30% , 70% {
    transform: translate(-15px, 10px)
  }
  40% , 60% {
    transform: translate(-30px, 30px)
  }
  50%  {
    transform: translate(25px, -10px)
  }
}

@keyframes searching2 {
 0% ,100% { transform: translate(5px,2px) }
 25% { transform: translate(72px,25px) }
 50% { transform: translate(25px,52px) }
 75% { transform: translate(-25px,25px) }
}

@keyframes searching3 {
 0%  { transform: rotate(0deg) }
 100% { transform: rotate(-360deg) }
}
@keyframes searching4 {
  0%  { transform: rotate(35deg) }
  70% { transform: rotate(320deg) }
  100% { transform: rotate(395deg) }
}

.searching-icon-container{
   position: fixed;
   left: 0;
   right: 0;
   height: 0; 
   z-index: 999;
  }
  
  .searching-animation{
    animation: searching4 2500ms linear infinite;
    display: flex;
    width: 180px;
    height:0;
    margin-inline: auto;
    margin-top: 150px;
  }
  
  .searching-part-conatiner{
    display: flex;
    align-items: center;
    animation: searching3 2500ms linear infinite;

}

.searching-icon { 
  border: 1px solid rgb(22 78 99);
  box-shadow: inset 0 0 4px rgb(22 78 99);
  border-radius: 170px;
  background-color:#ecfeffdd;
  /* padding: 27px; */
}
.clip-shin{
  clip-path: polygon(31% 9%, 46% 7%, 66% 12%, 82% 21%, 91% 36%, 94% 57%, 92% 78%, 85% 58%, 74% 30%, 49% 15%);
  margin: 4px;
  padding: 19px;
  background-color: #fffd;
  transform: rotate(-55deg);
}

.searching-icon-tail{
  height: 7px;
  background-color: rgb(22 78 99);
  width: 23px;
  border-radius: 0 6px 6px 0;
}


@keyframes bounce {
    0%, 100% {
     bottom:10px;
     padding-block: 40px;
     animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      padding-block: 38px;
      bottom:0;
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}

.animate-bounce {animation : bounce 1s infinite; position: absolute;};


/* Loading Animation */
.lds-ripple {
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid var(--throne);
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  margin: auto;
  background-color: var(--crow);
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}


[is="pulling"]{
  display: block !important;
}


.toggle-button{
  display: flex;
  margin-inline: 8px;
  align-items: center;
  flex-direction: column;
  margin-block:auto;
  gap: var(--md);
}

.toggleed-bg{
  cursor: pointer;
  padding: 2px;
  display: flex;
  margin: 0 4px;
  transition: all 0.2s ease-in-out;
  background-color: var(--prince);
  box-shadow: var(--shadow-md);
  padding-left: 22px;
  width: 69px;
  border-radius: var(--md);
}

.toggleed-bg span{
  padding: 2px 10px;
  box-shadow: var(--shadow-lg);
  font-size: var(--md);
  background-color: var(--prim);
  border-radius: var(--md);
}

.toggleed {
  padding-right: 22px;
  padding-left: 2px;
  background-color:var(--red) !important;   /* #F87171; */
}

.un-toggled{
  background-color:var(--green); /* #F87171; */
}


/*  */
/* 
*, *:before, *:after {
  box-sizing: border-box;
}

html {
  font-size: 18px;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  line-height: 1.6;
  background: #dbdbdb;
  &>div {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
}

label {
  // Just to make it bigger in the pen preview, 
  // it uses pixels, so it's not responsive
  transform: scale(1.5); 
  // end 
  display: block;
  width: 160px;
  background: #CCC;
  height: 80px;
  border-radius: 40px;
  background: linear-gradient(to bottom, #9e9e9e 30%, #f4f4f4);
  box-shadow: 0 2px 0 0 #fff, 0 -2px 0 0 #969494;
  position: relative;
  // Remove mobile tab color
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
  input {
    display: none;
  }
  
  div {
    display: block;
    width: 120px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(to bottom, #8b8c8e 20%, #f4f4f4);
    border-radius: 25px;
    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 46px;
      width: 116px;
      left: 2px;
      top: 2px;
      border-radius: 23px;
      background: #828080;
      box-shadow: inset 0 0 30px 0 rgba(0,0,0,0.8);
      transition: .20s;
    }
  }
  
  i {
    display: block;
    width: 60px;
    height: 60px;
    position: absolute;
    background: linear-gradient(to top, #9e9e9e 20%, #f4f4f4);
    border-radius: 50%;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.7);
    top: 10px;
    left: 15px;
    transition: .25s;
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 52px;
      height: 52px;
      left: 4px;
      top: 4px;
      border-radius: 50%;
      background: #d5d4d4;
      z-index: 1;
    }
  }
  
  input:checked ~ i {
    top: 10px;
    left: 86px;
  }
  
  input:checked + div:after {
    background: #f7931e;
    box-shadow: inset 0 0 30px 0 rgba(0,0,0,0.6);
  }
  
  input:checked + div > .off {
    color: transparent;
    text-shadow: 0 1px 0 rgba(255,255,255,0);
  }
  
  input:checked + div > .on{
    color: #c6631d;
    text-shadow: 0 1px 0 rgba(255,255,255, 0.3);
  }
  // LABEL GRADIENT BORDER
  &:after {
      content: "";
      position: absolute;
      display: block;
      width: 164px;
      height: 84px;
      border-radius: 42px;
      background: red;
      top: -2px;
      left: -2px;
      z-index: -1;
      background: linear-gradient(to bottom, #969494, #fff);
  }
  
  &:hover {
    cursor: pointer;
  }
  
  &:focus, &:active {
    outline: 0;
  }
}

.on, .off {
  text-transform: uppercase;
  position: absolute;
  left: 17px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2em;
  font-weight: 600;
  z-index: 2;
  -webkit-user-select: none;        
  -moz-user-select: none;
  -ms-user-select: none; 
  letter-spacing: 1px;
  transition: .25s;
}

.on {
  color: transparent;
  text-shadow: 0 1px 0 rgba(255,255,255,0);
}

.off {
  left: initial; 
  right: 17px;
  color: #444;
  text-shadow: 0 1px 0 rgba(255,255,255, 0.2);
}
 */

 
#refresher{
  width: 100px;
  margin-inline: auto;
  height: 100px;
  margin-top: -100px;
  position: fixed;
  left: 0;
  right: 0;
}

.refresher-svg {
  /* filter: drop-shadow( 0px 0px 15px #a5eb7877); */
  box-shadow:  0px 0px 20px #a5eb7877;
  border-radius: 50%;
  margin: 25px;
  fill: #000!important;
}

.pulling{
  transition: unset;
}
.reloading {
  opacity: 1;
  transition: all 300ms ease-out;
  margin-top: 0 !important;
  animation: reload 600ms linear infinite;
}
.disappearing{
  transition: all 0.3s ease-in-out;
  margin-top: -100px !important;
  opacity: 0;
}


@keyframes reload {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(720deg);
  }
}

.log-grid{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
    align-items: flex-start;
    gap: var(--md);
    padding: var(--md);
}
.logs-card {
    overflow: hidden;
    border-radius: var(--3xl);
    padding-inline:var(--md);
    box-shadow: var(--shadow-lg);
    background-color: var(--prim);
}
.logs-card .text {
    /* color: #343849; */
}
.logs-card-text {
    font-size: 14px;
}
.logs-card-title {
    font-size: var(--font-xl);
    text-align: left;
    color: var(--red);
}

.active-card {
    background-color: var(--red);
}


.logs-card-header {
    display: flex;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    font-size:var(--font-xl);
}

.logs-level {
    color: var(--orange);
    padding: var(--sm) var(--md);
    width: 20px;
    border-radius: var(--3xl);
}

.logs-card-content {
    /* border-top: 1px solid #ecf0f3; */
    font-family: var(--font-family);
    font-weight: bold;
    font-size: var(--font-base);
    color: var(--crow);
    direction: ltr;

    max-height: 1800px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    background-color: var(--throne);
    /* box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16); */
    border: 2px dashed #5f8b89;
    padding:var(--md) var(--2xl);
    margin:var(--md) 0;
    border-radius: var(--2xl);
}
.cut-words {
    max-height: 77px;
}
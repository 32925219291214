.toggle-theme-button{
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 333;
  padding: 5px;
  margin: -3px;
  background-color: var(--throne);
  border-radius: 0 0 10px 0 ;
  box-shadow: var(--shadow-lg);
  cursor: pointer;
}

a{text-decoration: none;}

input[type="number"],input[type="text"],input[type="password"] {
  font-family: "'BoutrosMBCDinkum Medium'";
  padding: var(--sm) var(--md);
  outline: none;
  border: none;
  color: var(--shark);
  font-weight: bold;
  border-radius: var(--md);
  box-shadow:var(--shadow-lg);
  background-color: var(--prim);
}

input::placeholder {
  color: var(--goat);
  letter-spacing: 1px;
  font-size: 14px;
  z-index: 2;
  font-weight: bold;
  position: relative;
}
input[type="number"]:focus,input[type="text"]:focus{box-shadow: var(--shadow-focus);}

.button{
  background-color: var(--dragon);
  color: var(--white);
  border-radius: var(--md);
  padding: var(--sm) var(--md);
  font-weight: bold;
  font-family: "'BoutrosMBCDinkum Medium'";
  border: none;
  cursor: pointer;
  letter-spacing: 1px;
  box-shadow: var(--shadow-lg);
}

.owl-expand{
  max-height: 1800px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

p[state='selected']{
  background-color: var(--penguin);
  }
  p[state='off']{
  background-color: var(--goat);
  }

  .more-options-container{
  position: absolute;
  /* top: 40px; */
  z-index: 999;
  background-color: var(--prim);
  box-shadow:var(--shadow-xl);
  padding: var(--sm);
  /* border: solid 2px var(--bg); */
  gap: var(--sm);
  display: flex;
  flex-direction: column;
}

.more-options-container.left{
  /* border-radius: 0 0 var(--md) 0; */
  border-radius: var(--md);
  left:0px;
}

.more-option {
  font-family:var(--font-bold);
  font-size: var(--lg);
  color: var(--crow);
  padding: var(--sm) var(--md);
  cursor: pointer;
  transition: all 0.2s ease-out;
  background-color: var(--prince);
  border-radius: var(--xs);
}

.fade-in-scale{
  animation: fade-in-scale 0.2s ease-out;
}

@keyframes fade-in-scale {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/*  */
.toggle-options-container{
  position: relative;
  background-color: var(--goat);
}
.toggle-options-container.toggle-off{
}

.toggle-options{
  position: absolute;
  top: 20px;
  margin-right: 100px;
}

.toggle-option{
  background-color: var(--goat);
  
}
/* .toggle-option:hover{
} */

.toggle-option.active{
  background-color: var(--cyan);
}
.spliter {
    background-color:inherit;
    /*display: grid ;
    grid-template-columns: repeat(auto-fill,1fr); */
    height: inherit;
    gap: 8px;
}

.spliter-left{
    background-color: #f005;
    border-right: 1px solid #E0E0E0;
    padding: 10px;
    height: calc(100% - 20px);
    width:auto;
}
.spliter-right{
    background-color: #f0f5;
    padding: 10px;
    height: calc(100% - 20px);
    width:auto;
    flex-shrink: 1;
}

.separative-line{
    background-color: #0009;
    flex-shrink: 1;
    padding: 2px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -7px;
    cursor: col-resize;
}